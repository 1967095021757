input {
  background-color: var(--white);
  border: 1px solid var(--grey-400);
  border-radius: 5px;
  padding: 7px 5px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  font-family: 'Lato', sans-serif;
  color: var(--text-black);
  transition: 0.3s ease;

  &:hover,
  &:focus {
    border-color: var(--primary);
  }

  &.invalid {
    border-color: var(--red-300);
    color: var(--red-300);
  }

  &::placeholder {
    opacity: 1;
  }
}
