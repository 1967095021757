@import 'assets/scss/index';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  color: var(--text-black);
  scroll-behavior: smooth;
}

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .page {
    flex: 1;
    position: relative;
    //min-height: 690px;
    //height: 100vh;
    position: relative;
    min-height: 100vh;
    height: 100%;

    @media only screen and (max-width: 900px) {
      min-height: 870px;
    }

    @media only screen and (max-width: 520px) {
      min-height: 990px;
    }
  }

  a {
    color: var(--primary);
    transition: 0.3s ease;
    text-decoration: none;

    &:hover {
      color: var(--primary-200);
    }
  }
}
