.article {
  font-size: 14px;

  .logo-box {
    margin-top: 20px;
  }

  header .container {
    display: flex;
    justify-content: center;
  }

  section {
    padding: 60px 0 230px;
  }

  .container {
    width: 790px;

    @media only screen and (max-width: 820px) {
      padding: 0 20px;
      width: 100%;
    }
  }

  &__image {
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;

    @media only screen and (max-width: 820px) {
      background-size: contain;
    }

    &--1 {
      background-image: url('../../assets/images/art-1.png');
      height: 161px;

      @media only screen and (max-width: 820px) {
        height: 105px;
      }

      @media only screen and (max-width: 500px) {
        height: 65px;
      }
    }

    &--2 {
      background-image: url('../../assets/images/art-2.png');
      height: 202px;

      @media only screen and (max-width: 820px) {
        height: 135px;
      }

      @media only screen and (max-width: 500px) {
        height: 80px;
      }
    }

    &--3 {
      background-image: url('../../assets/images/art-3.png');
      height: 384px;

      @media only screen and (max-width: 820px) {
        height: 260px;
      }

      @media only screen and (max-width: 500px) {
        height: 155px;
      }
    }

    &--4 {
      background-image: url('../../assets/images/art-4.png');
      height: 441px;

      @media only screen and (max-width: 820px) {
        height: 260px;
      }

      @media only screen and (max-width: 500px) {
        height: 155px;
      }
    }

    &--5 {
      background-image: url('../../assets/images/art-5.png');
      height: 436px;

      @media only screen and (max-width: 820px) {
        height: 288px;
      }

      @media only screen and (max-width: 500px) {
        height: 175px;
      }
    }

    &--6 {
      background-image: url('../../assets/images/art-6.png');
      height: 316px;

      @media only screen and (max-width: 820px) {
        height: 216px;
      }

      @media only screen and (max-width: 500px) {
        height: 130px;
      }
    }

    &--7 {
      background-image: url('../../assets/images/art-7.png');
      height: 169px;

      @media only screen and (max-width: 820px) {
        height: 115px;
      }

      @media only screen and (max-width: 500px) {
        height: 69px;
      }
    }

    &--8 {
      background-image: url('../../assets/images/art-8.png');
      height: 389px;

      @media only screen and (max-width: 820px) {
        height: 263px;
      }

      @media only screen and (max-width: 500px) {
        height: 158px;
      }
    }
  }

  &__quotation {
    width: 500px;
    text-align: center;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 820px) {
      width: 100%;
    }
  }

  h1 {
    font-size: 25px;
    margin-bottom: 28px;
  }

  h2 {
    margin-top: 35px;
    font-size: 21px;
    margin-bottom: 22px;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 14px;
  }

  p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .p--strong {
    font-weight: 600;
  }

  .image-box {
    text-align: center;
    margin-bottom: 20px;
  }

  .bold {
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }

  ul {
    padding-left: 25px;

    li {
      line-height: 24px;
      margin-bottom: 5px;
    }
  }

  ol {
    padding-left: 25px;

    li {
      margin-bottom: 5px;
    }
  }

  footer {
    .logo-box {
      margin-top: 0;
      background-color: var(--white);
      border-radius: 8px;
      justify-content: center;
      padding: 8px 12px;
    }
  }
}
