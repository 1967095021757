body {
  background-color: var(--white-100);
}

.container {
  display: block;
  margin: 0 auto;
  width: 1280px;
  box-sizing: border-box;

  @media only screen and (max-width: 1325px) {
    width: 1024px;
  }

  @media only screen and (max-width: 1160px) {
    width: 860px;
  }

  @media only screen and (max-width: 900px) {
    width: 480px;
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
    padding: 0 30px;
  }
}

form {
  position: relative;

  input + span {
    position: absolute;
    top: 56px;
    font-size: 14px;
    color: var(--red-300);

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 36px;
  margin-bottom: 16px;
}

h3 {
  line-height: 26px;
}

h4 {
  font-size: 20px;
  margin-bottom: 16px;
}

h5 {
  font-size: 18px;
  margin-bottom: 16px;
}

p {
  margin: 0;
  font-size: 15px;
  line-height: 22px;
}

.title-box {
  text-align: left;

  &--center {
    text-align: center;
  }
}
