.logo-box {
  border-radius: 8px;
  display: inline-flex;
  justify-content: left;
  align-items: center;

  &__logo {
    background-repeat: no-repeat;
    background-size: contain;
    width: 120px;
    height: 24px;
    background-image: url('../../assets/images/chatbeat-logo.png');
  }
}
