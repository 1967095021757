.header {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  min-height: 100vh;

  @media only screen and (max-width: 1160px) {
    min-height: unset;
  }

  .logo-box {
    margin-top: 14px;
  }

  .container {
    margin-top: auto;
    margin-bottom: auto;
    height: 556px;
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 1160px) {
      height: unset;
    }

    &__left {
      display: flex;
      flex-flow: column;
      gap: 33px;

      @media only screen and (max-width: 1160px) {
        width: 100%;
      }
    }

    &__right {
      @media only screen and (max-width: 1160px) {
        display: none;
      }
    }
  }

  &__img {
    background-image: url('../../assets/images/dashboard.png');
    width: 600px;
    height: 453px;
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (max-width: 1325px) {
      width: 472px;
      margin-top: 50px;
      height: 384px;
    }
  }

  h1 {
    color: var(--grey-600);
    margin: 0;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;

    @media only screen and (max-width: 900px) {
      font-size: 32px;
      line-height: 42px;
    }
  }

  h3 {
    color: var(--grey-700);
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    font-weight: 400;

    @media only screen and (max-width: 900px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  h6 {
    color: var(--white-200);
    font-size: 16px;
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;

    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
  }

  &__title-box {
    width: 420px;
    display: flex;
    flex-flow: column;
    margin-top: 25px;
    gap: 20px;
    z-index: 1;
    position: relative;

    @media only screen and (max-width: 1160px) {
      width: 100%;
      text-align: center;
    }
  }

  &__form-box {
    width: 620px;
    box-sizing: border-box;
    border-radius: 10px;
    z-index: 2;
    position: relative;

    @media only screen and (max-width: 1160px) {
      width: 480px;
      margin: 0 auto;
    }

    @media only screen and (max-width: 900px) {
      width: 100%;
    }

    form {
      display: flex;
      gap: 15px;

      @media only screen and (max-width: 1160px) {
        flex-flow: column;
        gap: 40px;
      }

      @media only screen and (max-width: 900px) {
        gap: 35px;
      }
    }

    h6 {
      margin-left: 10px;
      margin-bottom: 15px;

      @media only screen and (max-width: 1160px) {
        text-align: center;
        margin-left: 0;
      }
    }

    input {
      width: 412px;
      height: 48px;
      border-radius: 5px;
      font-size: 18px;
      font-weight: 400;
      color: var(--white-200);
      padding: 0 25px;
      box-sizing: border-box;

      @media only screen and (max-width: 1160px) {
        width: 100%;
        flex: unset;
      }

      &:hover,
      &:focus {
        border-color: var(--primary-500);
      }
    }

    button {
      flex: 1;
      background-color: var(--primary-400);
      transition: all 0.3s ease;
      border-radius: 5px;
      font-size: 18px;
      height: 50px;
      font-weight: 600;

      &:hover {
        background-color: var(--primary-500);
      }

      @media only screen and (max-width: 1160px) {
        width: 250px;
        margin: 0 auto;
        flex: unset;
      }

      @media only screen and (max-width: 900px) {
        width: 100%;
      }
    }
  }
}

section {
  padding: 100px 0;
}

footer {
  padding: 30px 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-image: linear-gradient(to left, var(--primary-100), var(--primary-200), var(--primary-300));
}

.footer {
  &__box {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 900px) {
      flex-flow: column-reverse;
    }
  }

  &__col {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    color: var(--white);

    &--social {
      justify-content: center;
      gap: 15px;

      @media only screen and (max-width: 900px) {
        margin-bottom: 55px;
      }

      a:nth-child(1) i {
        mask-image: url('../../assets/images/fb.svg');
        background-color: var(--secondary);
      }

      a:nth-child(2) i {
        mask-image: url('../../assets/images/x.svg');
        background-color: var(--secondary);
      }

      a:nth-child(3) i {
        mask-image: url('../../assets/images/in.svg');
        background-color: var(--secondary);
      }

      a i {
        mask-size: cover;
        mask-repeat: no-repeat;
        mask-position: center;
        height: 24px;
        width: 24px;
        display: block;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: var(--white);
        }
      }
    }

    &:first-child {
      flex-flow: column;
      align-items: flex-start;
      justify-content: center;
      gap: 55px;
    }

    &:last-child {
      @media only screen and (max-width: 900px) {
        text-align: center;
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  &__copyright {
    font-weight: 600;
    font-size: 12px;

    @media only screen and (max-width: 900px) {
      margin: auto;
    }
  }

  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;

    @media only screen and (max-width: 900px) {
      justify-content: center;
      flex-wrap: wrap;
    }

    a {
      color: var(--white);
      font-size: 14px;
      font-weight: 600;

      &:hover {
        color: var(--white);
        text-decoration: underline;
      }
    }
  }
}
