:root {
  --white: #fff;
  --black: #000;
  --text-black: #36435f;
  --primary: #064ee3;
  --primary-100: #1f5ce4;
  --primary-200: #1e5be4;
  --primary-300: #0c77f9;
  --primary-400: #10182f;
  --primary-500: #182546;
  --secondary: #d5d5d5;
  --secondary-100: #dbdbdb;
  --white-100: #f6f8fc;
  --white-200: #9ba0ad;
  --grey-100: #faf9ff;
  --grey-200: #e8e8e8;
  --grey-300: #c3c3c3;
  --grey-400: #9aaac5;
  --grey-500: #8c9fbe;
  --grey-600: #3a3a3a;
  --grey-700: #5b5e6b;
  --red-100: #ff7070;
  --red-200: #ffa8a8;
  --red-300: #f00;
  --green-100: #40b681;
  --green-200: #77d7b3;
  --yellow-100: #e0c951;
  --blue-100: #009efd;
  --blue-200: #26e5f5;
}
